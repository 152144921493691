<template>
  <div style="background: #f5f5f5;color: #000;height: 100%;">
    <van-nav-bar title="全部技师" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right></template>
    </van-nav-bar>
    <div style="padding-top:47px;background: #f5f5f5;">
      <div class="list" v-for="(item,idx) in yuangong" :key="idx" @click="tanYG(item)">
        <div class="list_a">
          <img class="list_img" v-if="item.picUrl" :src="item.picUrl" alt />
          <img class="list_img" v-else src="../../assets/img/touxiang.png" alt />
        </div>
        <div class="list_b">
          <div class="name">{{item.name}}</div>
          <div class="zhiye">{{item.type}}</div>
          <div class="zangsd" @click.stop="dianzang(item)">
            <img class="dian" src="../../assets/img/dian.png" alt />
            <span class="shu">{{item.greatCount}}</span>
          </div>
        </div>
        <div class="list_c">简介：{{item.introduction}}</div>
      </div>
    </div>
    <div style="height: 52px;"></div>
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '75%' }">
      <div class="tit_h1">
        <div class="til_img">
          <img class="tt_img" v-if="form.picUrl" :src="form.picUrl" alt />
          <img class="tt_img" v-else src="../../assets/img/touxiang.png" alt />
        </div>
        <div class="t_titil">
          <div class="f_name">{{form.name}}</div>
          <div>{{form.type}}</div>
        </div>
      </div>
      <div class="tit_h2">顾客评价</div>
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoads"
        :offset="10"
      >
        <div class="pin_list2" v-for="(item,index) in liaotianlist" :key="index">
          <div class="pin_a">
            <img class="c_touxian" v-if="item.avatar" :src="item.avatar" alt />
            <img class="c_touxian" v-else src="../../assets/img/touxiang.png" alt />
          </div>
          <div class="left pin_b">
            <div class="yonghu_name">
              <span class="left">{{item.name}}</span>
              <span class="right" style="color: #999;">{{item.createdDate}}</span>
            </div>
            <div class="xinji2">
              <!-- <span class="fen2" style="color:#ffd21e">{{item.grade?item.grade.toFixed(1):''}}分</span> -->
              <van-rate
                :size="15"
                allow-half
                class="xinc"
                color="#ffd21e"
                v-model="item.grade"
                readonly
              />
            </div>

            <div class="pinlun">{{item.content}}</div>
          </div>
        </div>
      </van-list>
      <div style="height: 45px;"></div>
      <div class="yuan_tuijian">推荐{{form.greatCount}}</div>
    </van-popup>
  </div>
</template>

<script>
import shopModel from "../../api/shop";
export default {
  data() {
    return {
      userInfo: {},
      gid: this.$route.query.gid,
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      active: 0,
      yuangong: [],
      list: [],
      liaotianlist: [],
      form: {},
      show: false,
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },

  methods: {
    //弹出员工评论列表
    tanYG(item) {
      this.page = 0;
      this.liaotianlist = [];
      this.form = item;
      this.show = true;
      this.YGpinlunlist();
    },
    YGpinlunlist() {
      var data = {
        eid: this.form.id,
        gid: this.gid,
        page: this.page,
        size: this.size
      };
      shopModel
        .pinlunlist(data)
        .then(e => {
          let rows = e.data;
          this.loading = false;
          this.total = e.pageInfo.total;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.liaotianlist = this.liaotianlist.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.liaotianlist.length >= this.total) {
            this.finished = true;
          }
          console.log(e);
          //   this.liaotianlist = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      this.page++;
      this.YGpinlunlist();
    },
    //点赞
    dianzang(item) {
      var _this = this;
      let data = {
        eid: item.id,
        type: item.flag == 0 ? 1 : 0,
        uid: this.userInfo ? this.userInfo.id : ""
        // uid: "1661"
      };
      shopModel
        .cancel(data)
        .then(e => {
          item.flag = item.flag == 0 ? 1 : 0;
          item.greatCount =
            item.flag == 0 ? item.greatCount - 1 : item.greatCount + 1;
          _this.$toast(e.data);
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      shopModel
        .yuangonglist({
          uid: this.userInfo ? this.userInfo.id : "",
          gid: this.gid
          // uid: 1661
        })
        .then(e => {
          // loading.clear();
          this.yuangong = e.data;
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    var _this = this;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // if (sessionStorage.getItem("Authorization")) {
    this.onLoad();
    // }
  },
  mounted() {
    window.addEventListener("pageshow", function(e) {
      // 通过persisted属性判断是否存在 BF Cache
      if (e.persisted) {
        // location.reload();
        this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      }
    });
  }
};
</script>

<style lang="less">
.list {
  overflow: hidden;
  padding: 15px;
  background: #fff;
  border-bottom: 1px solid #dcdcdc;
}
.list_a {
  float: left;
}
.list_img {
  width: 70px;
  height: 70px;
}
.list_b {
  float: left;
  font-size: 13px;
  margin-left: 15px;
  width: 25%;
}
.name {
  font-size: 15px;
  line-height: 16px;
}
.zhiye {
  line-height: 15px;
  margin-top: 7px;
}
.zangsd {
  display: inline-block;
  background: #ffd21e;
  line-height: 20px;
  overflow: hidden;
  padding: 0 6px;
  line-height: 18px;
  margin-top: 15px;
  border-radius: 2px;
}
.dian {
  width: 12px;
  vertical-align: middle;
}
.shu {
  vertical-align: middle;
  margin-left: 8px;
}
.list_c {
  font-size: 13px;
  line-height: 18px;
}
//-----------------弹窗-------------------
.tit_h1 {
  overflow: hidden;
  margin-top: 30px;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 10px;
}
.til_img {
  padding: 10px 20px;
  float: left;
}
.tt_img {
  width: 80px;
  height: 80px;
}
.t_titil {
  float: left;
  font-size: 13px;
  color: #666;
}
.f_name {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #333;
}
.tit_h2 {
  font-size: 15px;
  font-weight: bold;
  padding: 15px 20px;
}
.pin_list2 {
  // margin: 15px 0;
  overflow: hidden;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px 20px;
}
.pin_b {
  width: 89%;
}
.pin_a {
  float: left;
  width: 10%;
  height: 30px;
}
.c_touxian {
  float: left;
  width: 30px;
  border-radius: 15px;
}
.yonghu_name {
  overflow: hidden;
}
.xinji {
  height: 24px;
  overflow: hidden;
  line-height: 24px;
  font-size: 26px;
  margin-top: 2px;
}
.xinji2 {
  height: 24px;
  overflow: hidden;
  line-height: 24px;
  font-size: 18px;
}
.xinc {
  font-size: 16px;
}
.fen {
  color: #ee0a24;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
  line-height: 16px;
  //   margin-top: 0px;
  margin-left: 2px;
}
.fen2 {
  color: #ee0a24;
  font-size: 12px;
  display: inline-block;

  vertical-align: middle;
  line-height: 14px;
  margin-top: -2px;
  margin-right: 4px;
}

.yuan_tuijian {
  background: #ffd21e;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 94%;
  left: 50%;
  margin-left: -46%;
  height: 35px;
  text-align: center;
  line-height: 35px;
  font-size: 14px;
}
// --------------------------------------------
</style>
